import { IAgent } from "./AgentInterface";
import axios from "axios";
import { CUSTOM_HEADERS, ENV } from "../../utils";
import { handleAxiosError } from "../../axiosHelper";
import { getEnvKey } from "../../environment";
import { loggingHeaders } from "../../utils";

export default class Agent implements IAgent {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: number;
  parentAgentId?: number;

  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: number,
    parentAgentId?: number
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.parentAgentId = parentAgentId;
  }

  /**
   * @name loginAgent
   * @param agentId
   * @description this function takes the ID of an Agent and validates it to give it access
   * */
  loginAgent = async (agentId = this.id) => {
    return await axios
      .get(`${getEnvKey(ENV.REACT_APP_AGENTLOGINLAMBDAURL)}/${agentId}`,
    {
      headers: {
        [CUSTOM_HEADERS.agentId]: `${loggingHeaders.agentId}, attempted agent login ${agentId}`,
        [CUSTOM_HEADERS.sessionId]: loggingHeaders.sessionId,
      }
    })
      .then((response) => {
        const agent = JSON.parse(response.data);
        this.id = agent.ID;
        this.firstName = agent.FIRSTNAME;
        this.lastName = agent.LASTNAME;
        this.email = agent.EMAIL;
        this.phoneNumber = agent.PHONE1;
        this.parentAgentId = agent.PARENT.ID;
        loggingHeaders[CUSTOM_HEADERS.agentId] = agent.ID;
        return response;
      })
      .catch((error) => {
        return handleAxiosError(error)
      });
  };

  /**
   * @name getAgentlicnese
   * @param agentId
   * @description Gets the agent licenses
   * */
  getAgentLicense = async (agentId = this.id) => {
    return await axios
      .get(`${process.env.REACT_APP_AGENTLICENSELAMBDAURL}/${agentId}`,
        {
          headers: { ...loggingHeaders }
        }
      )
      .then((response) => {
        return response.data;
      });
  };
}
