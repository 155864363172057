import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Agent from "./models/Agent";
import Member from "./models/Member";
import AgentLogin from "./views/AgentLogin";
import Completed from "./views/Completed";
import Flow from "./views/Flow";
import { recordRumEvent, RUM_EVENTS } from "./services/awsRum";

function App() {
  const [member, setMember] = useState(new Member());
  const agent = new Agent(); // should we useState here to gain access to agent object within Routes?

  useEffect(() => {
    recordRumEvent(RUM_EVENTS.APP_INIT)
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AgentLogin agent={agent} />} />
        <Route path="/:agentId">
          <Route
            index
            element={
              <Flow
                agent={agent}
                memberInstance={member}
                setMemberInstance={setMember}
              />
            }
          />
          <Route
            path=":appId"
            element={
              <Flow
                agent={agent}
                memberInstance={member}
                setMemberInstance={setMember}
              />
            }
          />
        </Route>
        <Route
          path="/completed/:agentId"
          element={<Completed agent={agent} memberInstance={member} setMemberInstance={setMember}/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
