import { IAxiosResponse } from "../../axiosTypes";

export const invalidAgentIDMessage = 'Please enter a valid agent id';

export const agentIsInactive = (data: IAxiosResponse) => {
  if(!data || !data.data) return true;

  return JSON.parse(data.data).ACTIVE !== 1
}
export const isInvalidAgentLogin = (data: IAxiosResponse ) => {
  if (
    data.isAxiosError ||
    data.status !== 200 ||
    agentIsInactive(data)
  ) { return true; }
  else {
    return false;
  }
}

export const validAgentId = (id: string) => {
  // TODO add other agentID validations
  if (id.length > 0) {
    return true;
  } else {
    return false;
  }
}