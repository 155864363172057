import React, { FC, useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import cx from "classnames";
import Button from "../../components/Button";
import { invalidAgentIDMessage, isInvalidAgentLogin, validAgentId } from "../../views/AgentLogin/agentLoginHelper";
import { IAxiosResponse } from "../../axiosTypes";
import Agent from "../../models/Agent";
import { NCD_AGENT_SUPPORT_PHONE } from "../../utils";
import ErrorModal from "../Modals/ErrorModal";

type AgentLoginFormProps = {
  agent: Agent;
}

const AgentLoginForm: FC<AgentLoginFormProps> = ({ agent }: AgentLoginFormProps) => {
  const [agentID, setAgentID] = useState('');
  const navigate = useNavigate();

  const [invalidAgentID, setInvalidAgentID] = useState(false);
  const [invalidAnimation, setInvalidAnimation] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (invalidAgentID === true) {
      setInvalidAgentID(false);
      setInvalidAnimation(true);
      setTimeout(() => setInvalidAnimation(false), 10000);
    }
  }, [invalidAgentID]);

  useEffect(() => {
    document
      .querySelector("input")
      ?.addEventListener("keypress", function (evt) {
        if (evt.key !== "Enter") {
          if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        }
      });
  }, []);

  const handleAgentLoginSubmit = useCallback((
    agent: Agent,
    agentID: string,
    setInvalidAgentID: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    e: React.FormEvent,
    setPending: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    e.preventDefault();
    setPending(true);
    if (!validAgentId(agentID)) {
      setInvalidAgentID(true)
      setPending(false);
      return;
    }
    agent.id = Number(agentID);
    agent.loginAgent()
      .then((data) => {
        const invalid = isInvalidAgentLogin(data as IAxiosResponse);
        setInvalidAgentID(invalid); // type assertion, because TS wants IAxiosResponse | AxiosResponse. But, IAxiosResponse should incl AxiosResponse

        setPending(false);
        if (invalid) return;

        navigate(`/${agentID}`);
      });
  }, [agent, agentID, setInvalidAgentID, setPending])

  return (
    <form
      onSubmit={(e) => handleAgentLoginSubmit(agent, agentID, setInvalidAgentID, navigate, e, setPending)}
      className="relative z-50 flex h-[60%] flex-col items-center justify-center self-center justify-self-center px-2 font-inter sm:h-[70%]"
    >
      <div>
        <div className="rounded-3xl border-4 border-black bg-white p-4 xs:p-8 sm:px-[75px] sm:py-[51px]">
          <div
            className={cx(
              "text-lg leading-[24.2px]",
              "sm:text-xl",
              "text-left font-bold text-black"
            )}
          >
            Please enter your Agent ID
          </div>
          <div className="mt-[15px] flex flex-col items-start justify-start text-navyBlue">
            <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
              <div className="flex flex-col">
                <input
                  className={cx(
                    "h-[40px] w-full md:max-w-[215px]",
                    "appearance-none rounded-lg",
                    "border border-solid border-black bg-backgroundSolidGrey",
                    "pl-3 text-xl leading-[24.2px] focus:outline-none"
                  )}
                  type="number"
                  maxLength={6}
                  value={agentID}
                  onChange={(event) => {
                    setAgentID(
                      event.target.value.slice(0, 6).replace(/\D/g, "")
                    );
                    setInvalidAgentID(false);
                  }}
                />
                {invalidAnimation && (
                  <p className="mt-2 text-red md:hidden">
                    {invalidAgentIDMessage}
                  </p>
                )}
              </div>
              <div
                className={cx(
                  "h-[40px] w-full text-xl leading-[24.2px]",
                  "md:h-[40px] md:w-[121.38px] md:max-w-[145px]"
                )}
              >
                <Button text={"Submit"} disabled={pending} loadingIcon={pending} submit mainPath />
              </div>
            </div>
            <p
              className="mt-[15px] self-start text-start text-base font-medium leading-[19.36px] text-black"
            >
              Can't remember your Agent ID?
            </p>

            <a
              href={`tel:${NCD_AGENT_SUPPORT_PHONE}`}
              className="mt-[5px] cursor-pointer self-start text-lg font-bold leading-[19.36px] text-celadonBlue"
            >
              Call {NCD_AGENT_SUPPORT_PHONE}
            </a>

            <ErrorModal message={invalidAgentIDMessage} visible={invalidAnimation} />
          </div>
        </div>
      </div>
    </form>
)}

export default AgentLoginForm;