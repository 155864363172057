import { getEnvKey } from "./environment";
import Agent from "./models/Agent";

export const D2CAgentId = 660555;
export const testAgentID = 600539;
export const SVGUplines = [
  768925, // SVG
  782855, // Spring Insurance Solutions
  782856, // Smart Match Insurance Agency
]

export const nonValidSmileTopOfHierarchyIDs = [
  768925, //SVG
  782855, // Spring Insurance Solutions
  782856, // Smart Match Insurance Agency
  542167, //Realm
  542224, //SQ
]

export const LETTER_ID_DENTAL_DENTALandVISION = "28992";
export const LETTER_ID_VISION_ONLY_OR = "29249";
export const LETTER_ID_VISION_ONLY_DEFAULT = "29248";
export const LETTER_ID_ENROLLMENT_CONFIRMATION = "34545";

// for the purpose of autocompleting, store these in an object
export enum ENV {
  REACT_APP_AGENTLOGINLAMBDAURL = 'REACT_APP_AGENTLOGINLAMBDAURL',
  REACT_APP_API123METLIFE = 'REACT_APP_API123METLIFE',
  REACT_APP_AWS_RUM_APP_ID = 'REACT_APP_AWS_RUM_APP_ID',
  REACT_APP_SVGBILLINGDATECHECK = 'REACT_APP_SVGBILLINGDATECHECK',
  REACT_APP_VALIDATECCURL = 'REACT_APP_VALIDATECCURL',
  REACT_APP_ZIPCODELOOKUP = 'REACT_APP_ZIPCODELOOKUP'
}

// following naming conventions of Namespace-Capitalized-Kabob-Case
export const CUSTOM_HEADERS = {
  agentId: 'NCD-Agent-Id',
  sessionId: 'NCD-Session-Id'
}

export const loggingHeaders = {
  [CUSTOM_HEADERS.agentId]: "Not logged in",
  [CUSTOM_HEADERS.sessionId]: "Session not started"
}

export const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const aprilFirst = new Date(new Date().getFullYear(), 3, 1).getMonth();

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDateString(dateString: string) {
  return dateString.replaceAll("-", "/");
}

export function formatDate(date: Date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join("/");
}

export function displayDate(dateToDisplay: string) {
  let date = new Date(dateToDisplay);
  date = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  return date;
}

export function getMinDateBilling() {
  let currentDate = new Date();
  let monthString = "";
  let dayString = "";
  let maxYear = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  if (month < 10) monthString = "0" + month.toString();
  else monthString = month.toString();
  if (day < 10) dayString = "0" + day.toString();
  else dayString = day.toString();
  return maxYear + "-" + monthString + "-" + dayString;
}

export function getMaxDateBilling(agent: Agent) {
  let currentDate = new Date();
  let maxMonth = 2
  if (SVGUplines.includes(agent.parentAgentId as number)) {
    maxMonth = 6
  }
  let validDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + maxMonth,
    1
  );
  let monthString = "";
  let dayString = "";
  let maxYear = validDate.getFullYear();
  let month = validDate.getMonth() + 1;
  let day = validDate.getDate();
  if (month < 10) monthString = "0" + month.toString();
  else monthString = month.toString();
  if (day < 10) dayString = "0" + day.toString();
  else dayString = day.toString();
  return maxYear + "-" + monthString + "-" + dayString;
}

export function getMaxDate() {
  let currentDate = new Date();
  let monthString = "";
  let dayString = "";
  let maxYear = currentDate.getFullYear() - 18;
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  if (month < 10) monthString = "0" + month.toString();
  else monthString = month.toString();
  if (day < 10) dayString = "0" + day.toString();
  else dayString = day.toString();
  return maxYear + "-" + monthString + "-" + dayString;
}

export function getMaxDateChildren() {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let monthString = "";
  let dayString = "";
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  if (month < 10) monthString = "0" + month.toString();
  else monthString = month.toString();
  if (day < 10) dayString = "0" + day.toString();
  else dayString = day.toString();
  return currentDate.getFullYear() + "-" + monthString + "-" + dayString;
}

export function getMinDate() {
  let currentDate = new Date();
  let monthString = "";
  let dayString = "";
  let maxYear = currentDate.getFullYear() - 26;
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate() + 1;
  if (month < 10) monthString = "0" + month.toString();
  else monthString = month.toString();
  if (day < 10) dayString = "0" + day.toString();
  else dayString = day.toString();
  return maxYear + "-" + monthString + "-" + dayString;
}

export function dateIsValid(date?: string) {
  if (!date) return false;
  return !Number.isNaN(new Date(date).getTime());
}

export function formatPhoneNumber(phoneNumber?: string) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}

// @param date {string} - the billing date (likely on the member instance)
// @return value {boolean} - check if today <= billing date
export function isBillingCurrent(date?: string) {
  // Case 1 - date is undefined
  if (!date) return false;

  // Case 2 - date is defined
  let today = new Date();
  let billing = new Date(date);
  today.setHours(0, 0, 0, 0);

  let value = today <= billing;

  return value;
}

// nonValidDentalStates arrays:
// note that inclusion in nonValidDentalStates means the following more specific arrays won't do anything

export const nonValidDentalStates = [
  "AK",
  "ME",
  "MT",
  //"NC",
  "SD",
  "VT",
  "WA",
];

// CORE = value, essentials, complete
export const nonValidCoreStates = [
  "NH",
  "NM"
]

export const nonValidEliteStates = [
  "AK",
  "ME",
  "MT",
  "NH",
  "NM",
  "SD",
  "VT",
  "WA",
];

export const nonValidSmileStates = [
  "AK",
  "CT",
  "LA",
  "ME",
  "MS",
  "MT",
  "NV",
  "NY",
  "SD",
  "TX",
  "UT",
  "VT"
]
// END nonValidDentalStates

export const nonValidVisionStates = ["OR"];

export const noSalesState = ["WA"];

export const noSalesStateD2C: string[] = [];

export const sessionIDLambdaURL = `${process.env.REACT_APP_SESSIONIDLAMBDAURL}`;
export const E123ApiURL = `${process.env.E123APIURL}`;
export const memberCreateErrorHandlerZapierWebhookURL = `${process.env.MEMBERCREATEERRORHANDLERZAPIERWEBHOOKURL}`;
export const d2cWebhook = `${process.env.D2CWEBHOOK}`;
export const middlewareV1Hook =`${process.env.MIDDLEWAREV1HOOK}`;
export const middlewareV1_5Hook = `${process.env.MIDDLEWAREV1_5HOOK}`;
export const svgBillingDateCheck = getEnvKey(ENV.REACT_APP_SVGBILLINGDATECHECK);

export const NCDValueByMetLife = 38796;
export const NCDValueByMetLifeUpdate = 38797;
export const NCDEssentialsByMetLife = 38398;
export const NCDCompleteByMetLife = 38399;

export const NCDValueByMetLifeNYCT = 39849;
export const NCDEssentialsByMetLifeNYCT = 39847;
export const NCDCompleteByMetLifeNYCT = 39848;

export const NCDElite1500 = 42249;
export const NCDElite3000 = 42250;
export const NCDElite5000 = 42251;

export const NCDSmile1000byMetLife = 45035;
export const NCDSmile2500byMetLife = 45041;

export const VSPPreferredByMetLife = 38593;
export const VSPextra = 39651;

export const dentalPlansIDs = [
  NCDValueByMetLife,
  NCDEssentialsByMetLife,
  NCDCompleteByMetLife,
  NCDElite1500,
  NCDElite3000,
  NCDElite5000,
  NCDSmile1000byMetLife,
  NCDSmile2500byMetLife,
];

export const dentalPlansIDsNYCT = [
  NCDValueByMetLifeNYCT,
  NCDEssentialsByMetLifeNYCT,
  NCDCompleteByMetLifeNYCT,
  NCDElite1500,
  NCDElite3000,
  NCDElite5000,
  NCDSmile1000byMetLife,
  NCDSmile2500byMetLife,
];

export const dentalAssociationPlansIDs = [38449, 38450];

export const visionPlanIDs = [VSPPreferredByMetLife];
export const visionPlanIDsForNonValidStates = [VSPextra];
export const visionAssociationPlansIDs = [38923];

export const bundleAssociationPlansIDs = [38928, 38941];

export const validDentalAndValidVisionSteps = [
  "Start",
  "Dental Coverage",
  "Vision Coverage",
  "Amount of Dependents",
  "Dental",
  "Confirm Cart",
  "Primary",
  "Dependents",
  "Payment",
  "Confirmation",
  "Agreements",
  "Completed",
];

export const validDentalAndNoValidVisionSteps = [
  "Start",
  "Dental Coverage",
  "Vision Coverage",
  "Amount of Dependents",
  "Dental",
  "Confirm Cart",
  "Primary",
  "Dependents",
  "Payment",
  "Confirmation",
  "Agreements",
  "Completed",
];

export const notValidDentalAndValidVisionSteps = [
  "Start",
  "Vision Coverage",
  "Amount of Dependents",
  "Confirm Cart",
  "Primary",
  "Dependents",
  "Payment",
  "Confirmation",
  "Agreements",
  "Completed",
];

export const notValidDentalAndNotValidVisionSteps = [
  "Start",
  "Vision Coverage",
  "Amount of Dependents",
  "Confirm Cart",
  "Primary",
  "Dependents",
  "Payment",
  "Confirmation",
  "Agreements",
  "Completed",
];

export const somethingWentWrong = (isD2C: boolean, action: string = '') => {
  const supportPhone = isD2C ? NCD_MEMBER_CARE_PHONE : NCD_AGENT_SUPPORT_PHONE;
  action = (action.length > 0) ? action : 'while processing this request';
  const supportTeamName = isD2C ? 'Member Care Team' : 'Agent Support Team';
  return `Something went wrong ${action}. Please try again. If the issue persists please reach out to our ${supportTeamName}. ${supportPhone}`;
}

export const ZIP_CODE_VALID_LENGTH_MSG = 'Zip code must be 5 characters long';
export const INVALID_ZIP_CODE_MSG = 'Invalid zip code';
export const STATE_NOT_AVAILABLE_MSG = 'State is not available';


export const NCD_AGENT_SUPPORT_PHONE = '1-844-284-4944';
export const NCD_MEMBER_CARE_PHONE = '1-800-485-3855';
export const NCD_WELCOME_TEAM_EMAIL = 'welcometeam@ncd.com';
export const NCD_MEMBER_CARE_EMAIL = 'membercare@ncd.com';
export const NCD_APP_TECH_SUPPORT = 'AppTechSupport@NCD.com';