import React from "react";
import cx from "classnames";
import Agent from "../../models/Agent";
import {
  NCDCompleteByMetLife,
  NCDCompleteByMetLifeNYCT,
  NCDEssentialsByMetLife,
  NCDEssentialsByMetLifeNYCT,
  NCDValueByMetLife,
  NCDValueByMetLifeNYCT,
  NCDSmile1000byMetLife,
  NCDSmile2500byMetLife,
  VSPextra,
  VSPPreferredByMetLife,
} from "../../utils";
import Plan from "../../models/Plan";
import Button from "../Button";
import { PlanLabel } from "../../enums";

type PlanInfoProps = {
  fullViewArray?: string[];
  agent: Agent;
  plan: Plan;
  rows?: number;
  numberOfSelectedPlans: number;
  wantsDental: boolean;
  wantsVision: boolean;
  zipCodeState: string;
  associationLeftover: number;
  max: number;
  associationFee: number;
  selectedPlans: Plan[];
  amountOfDependentsType: string;
  selectable: boolean;
  priceQuote: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPlan?: React.Dispatch<React.SetStateAction<Plan>>;
  setFullViewArray: React.Dispatch<React.SetStateAction<string[]>>;
  cardPriceHandler: () => number;
};

function PlanInfo({
  fullViewArray,
  agent,
  plan,
  rows = 1,
  max,
  numberOfSelectedPlans,
  wantsDental,
  wantsVision,
  zipCodeState,
  associationLeftover,
  associationFee,
  selectedPlans,
  amountOfDependentsType,
  selectable,
  priceQuote,
  setFullViewArray,
  setStep,
  setSelectedPlan,
  cardPriceHandler,
}: PlanInfoProps) {
  const gTagClassName = () => {
    switch (plan.label) {
      case PlanLabel.NCDValueByMetLife:
        return "ncdValue";
      case PlanLabel.NCDEssentialsByMetLife:
        return "ncdEssentials";
      case PlanLabel.NCDCompleteByMetLife:
        return "ncdComplete";
    }
  };

  const calendarYearMaxHandler = () => {
    switch (plan.label) {
      case PlanLabel.NCDValueByMetLife:
      case PlanLabel.VSPPreferredPlan:
      case PlanLabel.NCDVisionORNY:
        return "$750";
      case PlanLabel.NCDEssentialsByMetLife:
        return "$2,000";
      case PlanLabel.NCDCompleteByMetLife:
        return "$10,000";
      case PlanLabel.MetLifeElite1500:
        return "$1,500";
      case PlanLabel.MetLifeElite3000:
        return "$3,000";
      case PlanLabel.MetLifeElite5000:
        return "$5,000";
      case PlanLabel.NCDSmile1000byMetLife:
        return "$1,000";
      case PlanLabel.NCDSmile2500byMetLife:
        return "$2,500";
      default:
        return "";
    }
  };

  const viewBenefitSummaryHandler = () => {
    switch (plan.label) {
      case PlanLabel.NCDValueByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/1XHQq8j1o_g1pCcEcbpsBvKbQCui6OlKk/view?usp=drive_link"
          : "https://drive.google.com/file/d/1dkTQSZlt1GA6-ofZ-HPSl2jIlJJuZzx-/view?usp=drive_link";
      case PlanLabel.NCDEssentialsByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/1UiBp5ykJLDHc7AbJ3Wz_NcjLyKbYO_He/view?usp=drive_link"
          : "https://drive.google.com/file/d/1uKQdgv5KWI_UO5IzcwRRMzdiHFigOJ2n/view?usp=drive_link";
      case PlanLabel.NCDCompleteByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/1pdZVgkL002Y7KobCKIpUCCtZcY1_IiOy/view"
          : "https://drive.google.com/file/d/1SmtqkIqTdCmfJ2HZolmFjkjDzh9k2UHz/view?usp=drive_link";
      case PlanLabel.VSPPreferredPlan:
        return zipCodeState === "OR"
          ? "https://drive.google.com/file/d/1GgiDu8eJBWR-Lj607dxv24cAtULx1t4I/view"
          : "https://drive.google.com/file/d/1Wbufo1wiB7GWlxIMMIg4XcatEbRFa6v8/view";
      case PlanLabel.NCDVisionORNY:
        return zipCodeState === "OR"
          ? "https://drive.google.com/file/d/1GgiDu8eJBWR-Lj607dxv24cAtULx1t4I/view"
          : "https://drive.google.com/file/d/1XHQq8j1o_g1pCcEcbpsBvKbQCui6OlKk/view?usp=drive_link"; // Same as Value NY/CT
      case PlanLabel.NCDSmile1000byMetLife:
        return "https://drive.google.com/file/d/1j7C_rPhvDP9a7G9_heQXckiQDx9WNNHb/view?usp=sharing";
      case PlanLabel.NCDSmile2500byMetLife:
        return "https://drive.google.com/file/d/1MmeSw81cTf6qVjyAKa1M03xM3ySzJqzt/view?usp=drive_link"
      default:
        // Elite Plans
        return "https://drive.google.com/file/d/1RATupSGOCq-HQSmvsF8NV0SwB4BoiaX5/view";
    }
  };

  const viewCompletePlanHandler = () => {
    switch (plan.label) {
      case PlanLabel.NCDValueByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/1ZOs-5Lz9DoLqE2IDfghKVDiNov2iKRO_/view?usp=drive_link"
          : "https://drive.google.com/file/d/13QqKrVt12HOR3eUw5F3LP32UsbG0ue2L/view?usp=drive_link";
      case PlanLabel.NCDEssentialsByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/17UMWgWsTeZ9IXrH4OohdbazJM70-O0wO/view?usp=drive_link"
          : "https://drive.google.com/file/d/1eqzDuIJ300hRQbHAMuGCdVcf3OgbKSRH/view?usp=drive_link";
      case PlanLabel.NCDCompleteByMetLife:
        return zipCodeState === "NY" || zipCodeState === "CT"
          ? "https://drive.google.com/file/d/1hQMjia3-24j902zpuac-wz7DHA2iMN1i/view?usp=drive_link"
          : "https://drive.google.com/file/d/1X35gdIjh7WKorVSBvqkd1WmQ6yi7qtGV/view?usp=drive_link";
      case PlanLabel.VSPPreferredPlan:
        return zipCodeState === "OR"
          ? "https://metlife-files.s3.us-west-2.amazonaws.com/VSP+1-Pager+OR+with+Price+2023+v1.pdf"
          : "https://metlife-files.s3.us-west-2.amazonaws.com/NCD+Preferred-VSP+Brochure-FINAL-Compressed+(1).pdf";
      case PlanLabel.NCDVisionORNY:
        return "https://metlife-files.s3.us-west-2.amazonaws.com/MetLife+Value-Agent+Brochure+-+NY-CT.pdf";
      case PlanLabel.MetLifeElite1500:
        return "https://drive.google.com/file/d/1lojQ9S6Ze4MOm36k8cjtoc0XQjMxgiUm/view?usp=drive_link";
      case PlanLabel.MetLifeElite3000:
        return "https://drive.google.com/file/d/1BUCoOqqRkDKBVJTVrgq5hdGnmgeIiJhE/view?usp=drive_link";
      case PlanLabel.MetLifeElite5000:
        return "https://drive.google.com/file/d/1I1w6Ce7k-NSLfermPhJW_XGmbSs1bSsS/view?usp=drive_link";
      case PlanLabel.NCDSmile1000byMetLife:
        return "https://drive.google.com/file/d/1Q2FPSuCRdiKAAeQBbUu0g0LiAA-_HbMj/view"
      case PlanLabel.NCDSmile2500byMetLife:
        return "https://drive.google.com/file/d/1mpZ1c4uTnt6HIDG-y3SbG4G-NrBmEcye/view"
      default:
        return "";
    }
  };

  const visionPlans: string[] = [
    PlanLabel.NCDVisionORNY,
    PlanLabel.VSPPreferredPlan,
  ];

  const defaultPlans: string[] = [
    PlanLabel.NCDValueByMetLife,
    PlanLabel.NCDEssentialsByMetLife,
    PlanLabel.NCDCompleteByMetLife,
  ];

  const elitePlans: string[] = [
    PlanLabel.MetLifeElite1500,
    PlanLabel.MetLifeElite3000,
    PlanLabel.MetLifeElite5000,
  ];

  const smilePlans: string[] = [
    PlanLabel.NCDSmile1000byMetLife,
    PlanLabel.NCDSmile2500byMetLife,
  ]

  const fullViewHandler = (remove: boolean) => {
    const planGroups: Record<number, Record<string, string[]>> = {
      3: {
        [PlanLabel.NCDValueByMetLife]: defaultPlans,
        [PlanLabel.NCDEssentialsByMetLife]: defaultPlans,
        [PlanLabel.NCDCompleteByMetLife]: defaultPlans,
        [PlanLabel.MetLifeElite1500]: elitePlans,
        [PlanLabel.MetLifeElite3000]: elitePlans,
        [PlanLabel.MetLifeElite5000]: elitePlans,
        [PlanLabel.NCDSmile1000byMetLife]: smilePlans,
        [PlanLabel.NCDSmile2500byMetLife]: smilePlans,
      },
      2: {
        [PlanLabel.NCDValueByMetLife]: defaultPlans.slice(0, 2),
        [PlanLabel.NCDEssentialsByMetLife]: defaultPlans.slice(0, 2),
        [PlanLabel.NCDCompleteByMetLife]: [
          PlanLabel.NCDCompleteByMetLife,
          PlanLabel.MetLifeElite1500,
        ],
        [PlanLabel.MetLifeElite1500]: [
          PlanLabel.NCDCompleteByMetLife,
          PlanLabel.MetLifeElite1500,
        ],
        [PlanLabel.MetLifeElite3000]: elitePlans.slice(1, 3),
        [PlanLabel.MetLifeElite5000]: elitePlans.slice(1, 3),
        [PlanLabel.NCDSmile1000byMetLife]: smilePlans.slice(0, 2),
        [PlanLabel.NCDSmile2500byMetLife]: smilePlans.slice(0, 2),
      },
      1: {
        [PlanLabel.NCDValueByMetLife]: [PlanLabel.NCDValueByMetLife],
        [PlanLabel.NCDEssentialsByMetLife]: [PlanLabel.NCDEssentialsByMetLife],
        [PlanLabel.NCDCompleteByMetLife]: [PlanLabel.NCDCompleteByMetLife],
        [PlanLabel.MetLifeElite1500]: [PlanLabel.MetLifeElite1500],
        [PlanLabel.MetLifeElite3000]: [PlanLabel.MetLifeElite3000],
        [PlanLabel.MetLifeElite5000]: [PlanLabel.MetLifeElite5000],
        [PlanLabel.VSPPreferredPlan]: [
          ...visionPlans,
          ...elitePlans,
          ...defaultPlans,
          ...smilePlans,
        ],
        [PlanLabel.NCDVisionORNY]: [
          ...visionPlans,
          ...elitePlans,
          ...defaultPlans,
          ...smilePlans,
        ],
        [PlanLabel.NCDSmile1000byMetLife]: [PlanLabel.NCDSmile1000byMetLife],
        [PlanLabel.NCDSmile2500byMetLife]: [PlanLabel.NCDSmile2500byMetLife],
      },
    };

    const selectedPlanLabel = plan?.label || "";
    const selectedPlans = planGroups[rows]?.[selectedPlanLabel] || [];

    const plansToAdd = [...selectedPlans, ...visionPlans];
    const plansToRemove = remove ? [...selectedPlans, ...visionPlans] : [];

    setFullViewArray((prevArray) =>
      remove
        ? prevArray.filter((plan) => !plansToRemove.includes(plan))
        : [...prevArray, ...plansToAdd]
    );

    if (plan.label) {
      const cardRef = document.getElementById(plan.label);
      if (cardRef) {
        cardRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  return (
    <div
      className={cx(
        "h-full rounded-bl-[20px] rounded-br-[20px] text-white",
        "text-xs leading-[24.2px] xs:text-base md:text-xl lg:max-w-[450.5px]"
      )}
    >
      {" "}
      {plan?.label && !fullViewArray?.includes(plan?.label) && (
        <div
          className={cx(
            "flex h-full flex-col rounded-bl-[19px] rounded-br-[19px]",
            {
              "bg-celadonBlue":
                plan?.label !== PlanLabel.MetLifeElite1500 &&
                plan?.label !== PlanLabel.MetLifeElite3000 &&
                plan?.label !== PlanLabel.MetLifeElite5000,
              "bg-navyBlue":
                plan?.label === PlanLabel.MetLifeElite1500 ||
                plan?.label === PlanLabel.MetLifeElite3000 ||
                plan?.label === PlanLabel.MetLifeElite5000,
              "bg-vspBlue":
                plan.label === PlanLabel.VSPPreferredPlan ||
                plan.label === PlanLabel.NCDVisionORNY,
              "bg-mediumBlue":
                plan.label === PlanLabel.NCDSmile1000byMetLife ||
                plan.label === PlanLabel.NCDSmile2500byMetLife,
            }
          )}
        >
          <div className={cx("flex flex-col px-8")}>
            <div className="mt-[27px]">
              {/* Show Frame Allowance on Vision Plans */}
              {plan?.id === VSPPreferredByMetLife || plan?.id === VSPextra ? (
                <div className="flex justify-between">
                  <p className="font-normal">Frame Allowance</p>
                  {/* Change Pricing in Oregon */}
                  {zipCodeState !== "OR" ? (
                    <p className="font-extrabold ">$200</p>
                  ) : (
                    <p className="font-extrabold ">$120</p>
                  )}
                </div>
              ) : (
                <div className="flex justify-between">
                  <p className="font-normal">Calendar Year Max</p>
                  <p className="font-extrabold ">{calendarYearMaxHandler()}</p>
                </div>
              )}
            </div>

            {zipCodeState !== "NY" && zipCodeState !== "CT" ? (
              <div className="mt-[21px] font-bold">
                {plan.label !== PlanLabel.NCDEssentialsByMetLife &&
                  plan.label !== PlanLabel.NCDCompleteByMetLife && (
                    <p className="invisible">No Waiting Period</p>
                  )}
                {(plan.label === PlanLabel.NCDEssentialsByMetLife ||
                  plan.label === PlanLabel.NCDCompleteByMetLife ||
                  plan.label === PlanLabel.NCDSmile1000byMetLife ||
                  plan.label === PlanLabel.NCDSmile2500byMetLife) && (
                    <p className="flex justify-end">No Waiting Period</p>
                  )}
              </div>
            ) : null}

            <div className="mt-[27px] flex justify-between">
              {" "}
              {plan?.id !== VSPPreferredByMetLife && plan?.id !== VSPextra ? (
                <>
                  <div className="font-normal">Total</div>

                  <div className="font-extrabold ">
                    ${cardPriceHandler()}
                    {/* 
                    {plan?.rate && associationFee
                      ? numberOfSelectedPlans &&
                        max === numberOfSelectedPlans &&
                        selectedPlans[1].id
                        ? Math.floor(associationFee / 2 + plan?.rate)
                        : associationFee + plan?.rate
                      : null}
                    {wantsVision &&
                      wantsDental &&
                      !!associationFee &&
                      associationFee % 2 !== 0 && (
                        <sup>
                          {(associationFee / 2 -
                            Math.floor(associationFee / 2)) *
                            100}
                        </sup>
                      )}
                      */}
                    /mo
                  </div>
                </>
              ) : (
                <>
                  <div className="font-normal">Total</div>
                  <div className="font-bold ">
                    ${cardPriceHandler()}
                    {/*
                    {plan?.rate && associationFee && numberOfSelectedPlans === 1
                      ? plan?.rate + associationFee
                      : plan?.rate &&
                        associationFee &&
                        Math.floor(plan?.rate + associationFee / 2)}
                    {wantsVision &&
                      wantsDental &&
                      associationFee &&
                      associationFee % 2 !== 0 && (
                        <sup>
                          {(associationFee / 2 -
                            Math.floor(associationFee / 2)) *
                            100}
                        </sup>
                      )}
                       */}
                    /mo
                  </div>
                </>
              )}
            </div>

            {(plan.label === PlanLabel.VSPPreferredPlan ||
              plan.label === PlanLabel.NCDVisionORNY) &&
              amountOfDependentsType === "Member plus Children" && (
                <div
                  className={cx(
                    "mt-6 w-full italic",
                    "font-medium text-white",
                    "text-sm sm:text-base md:text-lg"
                  )}
                >
                  Price will change to ${cardPriceHandler() + priceQuote}
                  &nbsp;if more than one child is added
                </div>
              )}

            {/* 
            {wantsDental &&
              wantsVision &&
              (plan.label === PlanLabel.VSPPreferredPlan ||
                plan.label === PlanLabel.NCDVisionORNY) && (
                <p
                  className={cx(
                    "mt-3 w-full italic",
                    "font-medium text-white",
                    "text-sm sm:text-base"
                  )}
                >
                  *Price discounted due to product bundling
                </p>
              )}
              */}
          </div>

          <div className="mt-auto">
            {selectable && (
              <div className="mx-auto mb-1 mt-[23px] h-[53px] max-w-[332px] px-2 font-bold">
                <Button
                  text="Select"
                  className={gTagClassName()}
                  action={() => {
                    if (setSelectedPlan) {
                      setSelectedPlan(plan);
                    }
                    setStep((current) => current);
                  }}
                  mainPath
                />
              </div>
            )}
            <div className={cx("my-5", "text-center font-bold", "text-white")}>
              <span
                className="cursor-pointer"
                onClick={() => {
                  fullViewHandler(false);
                }}
              >
                View Details
              </span>
            </div>
          </div>
        </div>
      )}
      {/* Card Height issue: if more content is added, this section and subsequent sections may need to be manually adjusted to prevent overflow */}
      {plan?.label && fullViewArray?.includes(plan?.label) && (
        <div className="max-h-[1280px] w-full rounded-bl-[20px] rounded-br-[20px] bg-white">
          <div
            className={cx("pl-[43px] pr-2", {
              "h-[355px]": smilePlans.includes(plan.label),
              "h-[322px]": visionPlans.includes(plan.label),
              "h-[290px]":
                defaultPlans.includes(plan.label) ||
                (selectedPlans[0] && selectedPlans[0].label && elitePlans.includes(selectedPlans[0].label)),
              "h-[260px] lg:h-[290px] xl:h-[260px]":
                elitePlans.includes(plan.label) &&
                (selectedPlans[0] && selectedPlans[0].label && !elitePlans.includes(selectedPlans[0].label)),
            })}
          >
            <div className="pt-8 font-bold leading-[24.2px] text-black underline">
              {(plan.label === PlanLabel.NCDValueByMetLife ||
                plan.label === PlanLabel.NCDEssentialsByMetLife ||
                plan.label === PlanLabel.MetLifeElite1500 ||
                plan.label === PlanLabel.MetLifeElite3000 ||
                plan.label === PlanLabel.MetLifeElite5000 ||
                plan.label === PlanLabel.NCDSmile1000byMetLife ||
                plan.label === PlanLabel.NCDSmile2500byMetLife) &&
                "Calendar Year Deductible"}
              {plan.label === PlanLabel.NCDCompleteByMetLife &&
                "Lifetime Deductible"}
              {(plan.label === PlanLabel.VSPPreferredPlan ||
                plan.label === PlanLabel.NCDVisionORNY) &&
                "Copay"}
            </div>
            <div className="mt-2 font-bold italic leading-[24.2px] text-navyBlue">
              {(plan.label === PlanLabel.NCDValueByMetLife ||
                plan.label === PlanLabel.NCDEssentialsByMetLife) && (
                  <>
                    <p>Basic & Major Coverage</p>
                    <p className="font-extrabold">$50</p>
                    <p className="invisible max-w-[236px]">
                      After Year 1: Vanishing Deductible
                    </p>
                  </>
                )}

              {plan.label === PlanLabel.NCDCompleteByMetLife && (
                <>
                  <p>Basic & Major Coverage</p>{" "}
                  <p>
                    Year 1: <span className="font-extrabold">$100</span>{" "}
                  </p>
                  <p className="max-w-[236px]">
                    After Year 1: Vanishing Deductible
                  </p>
                </>
              )}
              {(plan.label === PlanLabel.NCDSmile1000byMetLife) && (
                <>
                  <p className="mt-2 max-w-[250px] italic text-navyBlue lg:max-w-[331px]">
                    Applies to Type A, B, and C Services
                  </p>
                  <p className="max-w-[350px]">
                    In-Network: {" "}
                    <span className="font-extrabold">$0</span>
                  </p>
                  <p className="max-w-[350px]">
                    Out-of-Network:{" "}
                    <span className="font-extrabold">$100</span> Per Individual /{" "}
                    <span className="font-extrabold">$300</span> Per Family Per
                    Year{" "}
                  </p>
                </>
              )}
              {(plan.label === PlanLabel.NCDSmile2500byMetLife) && (
                <>
                  <p className="mt-2 max-w-[250px] italic text-navyBlue lg:max-w-[331px]">
                    Applies to Type A, B, and C Services
                  </p>
                  <p className="max-w-[350px]">
                    In-Network: {" "}
                    <span className="font-extrabold">$0</span>
                  </p>
                  <p className="max-w-[350px]">
                    Out-of-Network:{" "}
                    <span className="font-extrabold">$50</span> Per Individual /{" "}
                    <span className="font-extrabold">$150</span> Per Family Per
                    Year{" "}
                  </p>
                </>
              )}
              {(plan.label === PlanLabel.MetLifeElite1500 ||
                plan.label === PlanLabel.MetLifeElite3000 ||
                plan.label === PlanLabel.MetLifeElite5000) && (
                  <>
                    <p>Basic & Major Coverage</p>{" "}
                    <p className="max-w-[289px]">
                      <span className="font-extrabold">$50</span> Per Individual /{" "}
                      <span className="font-extrabold">$150</span> Per Family Per
                      Year{" "}
                    </p>
                  </>
                )}
              {(plan.label === PlanLabel.VSPPreferredPlan ||
                plan.label === PlanLabel.NCDVisionORNY) && (
                  <>
                    <p className="max-w-[304px]">
                      Every 12 months for WellVision Exam
                    </p>
                    {plan.label === PlanLabel.NCDVisionORNY ? (
                      <p className="font-extrabold"> $15</p>
                    ) : (
                      <p className="font-extrabold"> $20</p>
                    )}

                    <p className="invisible font-bold">
                      After Year 1: Vanishing Deductible
                    </p>
                  </>
                )}
            </div>

            <div className="pt-4 font-bold leading-[24.2px]">
              {plan.label !== PlanLabel.VSPPreferredPlan &&
                plan.label !== PlanLabel.NCDVisionORNY && (
                  <>
                    <p className="text-black underline">
                      Calendar Year Maximum
                    </p>
                    <p className="mt-2 max-w-[250px] italic text-navyBlue lg:max-w-[331px]">
                      Applies to Type A, B, and C Services
                    </p>
                  </>
                )}
              {plan.label === PlanLabel.NCDSmile1000byMetLife && (
                <>
                  <p className="max-w-[350px] text-navyBlue">
                    In-Network: {" "}
                    <span className="font-extrabold">$1000</span>{" "}
                  </p>
                  <p className="max-w-[350px] text-navyBlue">
                    Out-of-Network:{" "}
                    <span className="font-extrabold">$500</span>{" "}
                  </p>
                </>
              )}
              {plan.label === PlanLabel.NCDSmile2500byMetLife && (
                <>
                  <p className="max-w-[350px] text-navyBlue">
                    In-Network: {" "}
                    <span className="font-extrabold">$2500</span>{" "}
                  </p>
                  <p className="max-w-[350px] text-navyBlue">
                    Out-of-Network:{" "}
                    <span className="font-extrabold">$2000</span>{" "}
                  </p>
                </>
              )}

              {(plan.label === PlanLabel.VSPPreferredPlan ||
                plan.label === PlanLabel.NCDVisionORNY) && (
                  <>
                    <p className="text-black underline">Frame</p>
                    <p className="mt-2 font-bold italic text-navyBlue">
                      Allowance for wide selection
                    </p>
                  </>
                )}
            </div>

            <div className="font-extrabold italic text-navyBlue">
              {plan.label === PlanLabel.VSPPreferredPlan && "$200"}
              {plan.label === PlanLabel.NCDVisionORNY && "$120"}

              {plan.label === PlanLabel.NCDValueByMetLife && "$750"}
              {plan.label === PlanLabel.NCDEssentialsByMetLife && "$2,000"}
              {plan.label === PlanLabel.NCDCompleteByMetLife && "$10,000"}
              {plan.label === PlanLabel.MetLifeElite1500 && "$1,500"}
              {plan.label === PlanLabel.MetLifeElite3000 && "$3,000"}
              {plan.label === PlanLabel.MetLifeElite5000 && "$5,000"}
            </div>
          </div>
          <div className="mx-auto mt-6 h-[2px] w-[95%] bg-black" />
          {/* Card Height issue: if content is added to previous Plan Details sections, exact pixel height may need to be adjusted here to prevent overflow
            * Exact pixel size is also being used for the compare selected plans view,
            * so if content within a Plan Details card changes, we will want to revisit this pixel count, or if plans change, add/remove plans from the includes below
          */}
          <div
            className={cx("pl-[43px]", {
              "md:h-[341px]": smilePlans.includes(plan.label),
              "md:h-[466px]": visionPlans.includes(plan.label),
              "h-[330px] md:h-[370px]":
                defaultPlans.includes(plan.label) ||
                (selectedPlans[0] && selectedPlans[0].label && elitePlans.includes(selectedPlans[0].label)),
              "h-[210px] md:h-[240px] lg:h-[370px] xl:h-[240px]":
                elitePlans.includes(plan.label) &&
                (selectedPlans[0] && selectedPlans[0].label && !elitePlans.includes(selectedPlans[0].label)),
            })}
          >
            <div className="mt-5 pr-2 text-black">
              <div className="font-bold underline">
                {plan.label === PlanLabel.VSPPreferredPlan ||
                  plan.label === PlanLabel.NCDVisionORNY ? (
                  "Lenses"
                ) : plan.label === PlanLabel.NCDSmile1000byMetLife ? (
                  "Preventive Services (Type A)"
                ) : plan.label === PlanLabel.NCDSmile2500byMetLife ? (
                  "Preventive Services (Type A)"
                ) : (
                  "Preventive Services (Type A)"
                )}
              </div>
              <div className="mt-2 font-bold italic text-navyBlue">
                {plan.label === PlanLabel.VSPPreferredPlan ||
                  plan.label === PlanLabel.NCDVisionORNY ? (
                  <>
                    <p>Single Vision</p>
                    <p>Lined Bifocal</p>
                    <p>Lined Trifocal Lenses</p>
                  </>
                ) : plan.label === PlanLabel.NCDSmile1000byMetLife ? (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">55%</span>{" "}
                    </p>
                  </>
                ) : plan.label === PlanLabel.NCDSmile2500byMetLife ? (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">85%</span>{" "}
                    </p>
                  </>
                ) : (
                  <span className="font-extrabold">100%</span>
                )}
              </div>
              <div className="mt-2 font-bold underline">
                {plan.label !== PlanLabel.VSPPreferredPlan &&
                  plan.label !== PlanLabel.NCDVisionORNY
                  ? "Basic Services (Type B)"
                  : "Contacts"}
              </div>
              <div className="mt-2 font-bold italic text-navyBlue">
                {plan.id === NCDSmile1000byMetLife && (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">25%</span>{" "}
                    </p>
                  </>
                )}
                {plan.id === NCDSmile2500byMetLife && (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">50%</span>{" "}
                    </p>
                  </>
                )}
                {plan.id === NCDValueByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">50%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">65%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">80%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDValueByMetLifeNYCT && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">50%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">75%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">90%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDEssentialsByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">65%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">80%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">90%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDCompleteByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">65%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">80%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">90%</span>
                    </p>
                  </div>
                )}
                {(plan.id === NCDCompleteByMetLifeNYCT ||
                  plan.id === NCDEssentialsByMetLifeNYCT) && (
                    <div>
                      <p>
                        Year 1: <span className="font-extrabold">80%</span>
                      </p>
                      <p>
                        Year 2: <span className="font-extrabold">80%</span>
                      </p>
                      <p>
                        Year 3: <span className="font-extrabold">90%</span>
                      </p>
                    </div>
                  )}
                {(plan.label === PlanLabel.MetLifeElite1500 ||
                  plan.label === PlanLabel.MetLifeElite3000 ||
                  plan.label === PlanLabel.MetLifeElite5000) && (
                    <span className="font-extrabold">80%</span>
                  )}
                {(plan.label === PlanLabel.VSPPreferredPlan ||
                  plan.label === PlanLabel.NCDVisionORNY) && (
                    <>
                      <p className="font-bold text-navyBlue">
                        {" "}
                        Allowance for contacts
                      </p>
                      {plan.label === PlanLabel.NCDVisionORNY ? (
                        <p className="font-extrabold text-navyBlue"> $120</p>
                      ) : (
                        <p className="font-extrabold text-navyBlue"> $150</p>
                      )}
                    </>
                  )}
              </div>
              <div className="mt-2 font-bold underline">
                {plan.label !== PlanLabel.VSPPreferredPlan &&
                  plan.label !== PlanLabel.NCDVisionORNY
                  ? "Major Services (Type C)"
                  : "Out of Network Providers"}
              </div>
              <div className="mt-2 font-bold italic text-navyBlue">
                {plan.id === NCDSmile1000byMetLife && (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">25%</span>{" "}
                    </p>
                  </>
                )}
                {plan.id === NCDSmile2500byMetLife && (
                  <>
                    <p className="max-w-[350px] text-navyBlue">
                      In-Network: {" "}
                      <span className="font-extrabold">See Copay Schedule</span>{" "}
                    </p>
                    <p className="max-w-[350px] text-navyBlue">
                      Out-of-Network:{" "}
                      <span className="font-extrabold">30%</span>{" "}
                    </p>
                  </>
                )}
                {plan.id === NCDValueByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">0%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">10%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">25%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDValueByMetLifeNYCT && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">0%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">0%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">0%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDEssentialsByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">10%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">50%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">60%</span>
                    </p>
                  </div>
                )}
                {plan.id === NCDCompleteByMetLife && (
                  <div>
                    <p>
                      Year 1: <span className="font-extrabold">10%</span>
                    </p>
                    <p>
                      Year 2: <span className="font-extrabold">50%</span>
                    </p>
                    <p>
                      Year 3: <span className="font-extrabold">60%</span>
                    </p>
                  </div>
                )}
                {(plan.id === NCDCompleteByMetLifeNYCT ||
                  plan.id === NCDEssentialsByMetLifeNYCT) && (
                    <div>
                      <p>
                        Year 1: <span className="font-extrabold">0%</span>
                      </p>
                      <p>
                        Year 2: <span className="font-extrabold">50%</span>
                      </p>
                      <p>
                        Year 3: <span className="font-extrabold">60%</span>
                      </p>
                    </div>
                  )}
                {(plan.label === PlanLabel.MetLifeElite1500 ||
                  plan.label === PlanLabel.MetLifeElite3000 ||
                  plan.label === PlanLabel.MetLifeElite5000) && (
                  <>
                    <p>
                      Year 1:{" "}
                      <span className="font-extrabold">
                        {zipCodeState !== "NY" && zipCodeState !== "CT"
                          ? `10%`
                          : `0%`}
                      </span>
                    </p>
                    <p>
                      After Year 2: <span className="font-extrabold">50%</span>
                    </p>
                  </>
                )}
                {(plan.label === PlanLabel.VSPPreferredPlan ||
                  plan.label === PlanLabel.NCDVisionORNY) && (
                    <div>
                      <div>
                        Exam: <span className="font-extrabold">$45</span>
                      </div>
                      <div>
                        Frame: <span className="font-extrabold">$70</span>
                      </div>
                      <div>
                        Contacts: <span className="font-extrabold">$105</span>
                      </div>
                    </div>
                  )}
              </div>

              {zipCodeState !== "NY" && zipCodeState !== "CT" ? (
                <div className="pt-4 text-xl font-extrabold italic leading-[29.05px] text-navyBlue md:text-2xl">
                  {plan.label !== PlanLabel.NCDEssentialsByMetLife &&
                    plan.label !== PlanLabel.NCDCompleteByMetLife &&
                    plan.label !== PlanLabel.NCDSmile1000byMetLife &&
                    plan.label !== PlanLabel.NCDSmile2500byMetLife && (
                      <p className="invisible">No Waiting Period</p>
                    )}
                  {(plan.label === PlanLabel.NCDEssentialsByMetLife ||
                    plan.label === PlanLabel.NCDCompleteByMetLife ||
                    plan.label === PlanLabel.NCDSmile1000byMetLife ||
                    plan.label === PlanLabel.NCDSmile2500byMetLife) && (
                      <p>No Waiting Period</p>
                    )}
                </div>
              ) : null}
            </div>

          </div>
          {plan.label !== PlanLabel.NCDValueByMetLife &&
            plan.label !== PlanLabel.NCDEssentialsByMetLife &&
            plan.label !== PlanLabel.NCDCompleteByMetLife &&
            plan.label !== PlanLabel.MetLifeElite1500 &&
            plan.label !== PlanLabel.MetLifeElite3000 &&
            plan.label !== PlanLabel.MetLifeElite5000 &&
            plan.label !== PlanLabel.NCDVisionORNY &&
            plan.label !== PlanLabel.VSPPreferredPlan && (
              <>
                <div className="h-[125px] pt-4 text-xl font-extrabold italic">
                  <div className="mx-auto mt-4 mb-1 h-[53px] max-w-[332px] px-2 font-bold">
                    <Button
                      text="View Copay Schedule Here"
                      action={() => {
                        window.open(
                          plan.label === PlanLabel.NCDSmile1000byMetLife
                            ? 'https://drive.google.com/file/d/1P5d8Wjrx76GB_e_UIi51rxfFRm1jq98-/view'
                            : plan.label === PlanLabel.NCDSmile2500byMetLife
                              ? 'https://drive.google.com/file/d/188jo1BcbWPD06y_9g-ngSYhVAVh-NQTP/view'
                              : '',
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )
          }

          <div className="mx-auto mt-6 h-[2px] w-[95%] bg-black" />

          <div className="mt-6 flex flex-col">
            <div
              className={cx("flex flex-col gap-7 md:gap-9", "pl-[43px] pb-8")}
            >
              <a
                className="flex w-full font-bold text-blue"
                href={
                  plan.label !== PlanLabel.VSPPreferredPlan &&
                    plan.label !== PlanLabel.NCDVisionORNY
                    ? `https://providers.online.metlife.com/findDentist?searchType=findDentistRetail&planType=DPPO`
                    : `https://www.vsp.com/eye-doctor`
                }
                target="_blank"
                rel="noreferrer"
              >
                View a List of Providers{" "}
                <img
                  className="blue-icon ml-2 pr-2"
                  src={"/download-doc-icon.svg"}
                  alt="Download Doc Icon"
                />
              </a>

              <a
                className="flex w-full font-bold text-blue"
                href={viewBenefitSummaryHandler()}
                target="_blank"
                rel="noreferrer"
              >
                View Benefit Summary
                <img
                  className="blue-icon ml-2 pr-2"
                  src={"/download-doc-icon.svg"}
                  alt="Download Doc Icon"
                />
              </a>

              <a
                className={cx("flex w-full font-bold text-blue", {
                  invisible:
                    plan.label === PlanLabel.NCDVisionORNY ||
                    plan.label === PlanLabel.VSPPreferredPlan,
                })}
                href={viewCompletePlanHandler()}
                target="_blank"
                rel="noreferrer"
              >
                View Complete Plan Details
                <img
                  className="blue-icon ml-2 pr-2"
                  src={"/download-doc-icon.svg"}
                  alt="Download Doc Icon"
                />
              </a>
            </div>
            <div className="h-3.5 w-full bg-black"></div>
            <div
              className={cx("rounded-bl-[19px] rounded-br-[19px] text-white", {
                "bg-celadonBlue":
                  plan?.label !== PlanLabel.MetLifeElite1500 &&
                  plan?.label !== PlanLabel.MetLifeElite3000 &&
                  plan?.label !== PlanLabel.MetLifeElite5000,
                "bg-navyBlue":
                  plan?.label === PlanLabel.MetLifeElite1500 ||
                  plan?.label === PlanLabel.MetLifeElite3000 ||
                  plan?.label === PlanLabel.MetLifeElite5000,
                "bg-vspBlue":
                  plan.label === PlanLabel.VSPPreferredPlan ||
                  plan.label === PlanLabel.NCDVisionORNY,
                "bg-mediumBlue":
                  plan.label === PlanLabel.NCDSmile1000byMetLife ||
                  plan.label === PlanLabel.NCDSmile2500byMetLife,
              })}
            >
              {selectable && (
                <div className="mx-auto mt-8 mb-1 h-[53px] max-w-[332px] px-2 font-bold">
                  <Button
                    text="Select"
                    className={gTagClassName()}
                    action={() => {
                      if (setSelectedPlan) {
                        setSelectedPlan(plan);
                      }
                      setStep((current) => current);
                    }}
                    mainPath
                  />
                </div>
              )}
              <div className={cx("my-5", "text-center font-bold")}>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    fullViewHandler(true);
                  }}
                >
                  Close Details
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlanInfo;
