import { FC, useEffect } from "react";
import cx from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import Submitted from "../Agreements/Submitted";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import { recordRumEvent, RUM_EVENTS } from "../../services/awsRum";

type CompletedProps = {
  agent: Agent
  memberInstance: Member
  setMemberInstance: React.Dispatch<React.SetStateAction<Member>>;
}
interface CompletedLocationState {
  agentId: string,
  memberId: number,
  memberFirstName: string,
  memberLastName: string,
}

const Completed: FC<CompletedProps> = ({
  agent,
  memberInstance,
  setMemberInstance
}: CompletedProps ) => {

  // get agent and member from state passed into location call
  const { state } = useLocation(); // state could be null...
  let memberId: number = 0;
  let memberFirstName;
  let memberLastName;
  let agentId: string = '';
  if (state && (state as CompletedLocationState)) {
    const completedState = state as CompletedLocationState;
    agentId = completedState.agentId
    memberId = completedState.memberId;
    memberFirstName = completedState.memberFirstName
    memberLastName = completedState.memberLastName
  }
  // but can I get this from the Route? Yes, memberInstance at least
  // console.log('memberInstance is', memberInstance)
  // console.log('agent is', agent) // perhaps agent too if we set it to useState within App.tsx

  useEffect(() => {
    const eventData = {stepName: 'Completed', agentId, parentAgentId: agent?.parentAgentId, memberId}
    recordRumEvent(RUM_EVENTS.APP_COMPLETE, eventData)
  }, [agentId, memberId])

  return (
    <div
      className={cx(
        "h-full w-full",
        "bg-gradient-to-r from-[#003764] to-[#53BFE7]"
      )}
    >
      <Header agentState={agent} />
      <div className="border-b-2 border-b-orange"></div>

      <Submitted agentId={agentId} memberFirstName={memberFirstName} memberLastName={memberLastName} memberId={memberId} setMemberInstance={setMemberInstance} />

      <div
        className={`fixed bottom-0 z-30 ml-auto mr-auto w-full bg-orange pt-1`}
      >
        <ProgressBar step={1} />
      </div>
    </div>
  );
};

export default Completed;
