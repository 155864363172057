import React, { FC, useEffect, useState } from "react";
import cx from "classnames";
import Button from "../../components/Button";
import Dependent from "../../models/Dependent";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import Plan from "../../models/Plan";
import {
  dateIsValid,
  formatDate,
  formatDateString,
  getMaxDateBilling,
  getMinDateBilling,
  aprilFirst,
  isBillingCurrent,
  SVGUplines,
} from "../../utils";
import BillingDateModal from "../BillingDateModal";
import { Tooltip } from "@material-tailwind/react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import { PlanLabel } from "../../enums";
import useApplicationStore from "../../context/application";
import metlifeDentalData from "../../metlifeJsonData";

type ConfirmationProps = {
  agent: Agent;
  member: Member;
  selectedPlans: Plan[];
  associatedPlan: Plan;
  dependents: Dependent[];
  wantsDental: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPlans: React.Dispatch<React.SetStateAction<Plan[]>>;
};

const Confirmation: FC<ConfirmationProps> = ({
  member,
  agent,
  selectedPlans,
  associatedPlan,
  dependents,
  wantsDental,
  setStep,
  setSelectedPlans,
}: ConfirmationProps) => {
  const [dependentsArray, setDependentsArray] =
    useState<Dependent[]>(dependents);
  const [totalValue, setTotalValue] = useState(0);
  const [validDates, setValidDates] = useState<Date[]>();

  const [errorDate, setErrorDate] = useState("");

  const [invalidBillingDate, setInvalidBillingDate] = useState(false);

  const [billingDate, setBillingDate] = useState<Date>(
    member.billingDate ? new Date(member.billingDate) : new Date()
  );

  const [missingFields, setMissingFields] = useState<string[]>([]);

  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<string>(
    member.effectiveDate || ""
  );
  const [applicationDate, setApplicationDate] = useApplicationStore((state) => [
    state.applicationDate,
    state.setApplicationDate,
  ]);

  useEffect(() => {
    if (validDates && (!member.effectiveDate || !selectedEffectiveDate)) {
      setSelectedEffectiveDate(formatDate(validDates[0]));
      member.effectiveDate = formatDate(validDates[0]);
    }
    // console.log('useeffecets', validDates, agent.parentAgentId)
  }, [validDates]);

  useEffect(() => {
    if (member.billingDate && dateIsValid(member.billingDate)) {
      member.billingDate = formatDateString(
        formatDate(new Date(member.billingDate))
      );
    } else {
      member.billingDate = formatDateString(formatDate(new Date()));
    }
  }, []);

  {
    /*
  useEffect(() => {
    if (member.billingDate && member.effectiveDate) {
      if (new Date(member.billingDate) > new Date(member.effectiveDate)) {
        setErrorDate("Date can't be before today");
      } else {
        setErrorDate("");
      }
    }
  }, [member.billingDate, member.effectiveDate]);
   */
  }

  function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month, 1);
  }
  // USed to update valid dates on date picked
  const handleChangeDate = (date: Date) => {
    if (date < new Date(getMinDateBilling())) {
      setErrorDate("Date can't be before today");
      return false;
    }
    let newValidDates: Date[] = [];
    let monthsToAdd = SVGUplines.includes(agent.parentAgentId!) ? 6 : 2;
    const today = new Date();

    for (let i = 1; i <= monthsToAdd; i++) {
      const month = today.getMonth() + i;
      const validDate = getFirstDayOfMonth(today.getFullYear(), month);
      if (validDate > date) {
        newValidDates.push(validDate);
      }
    }
    if (newValidDates.length === 0) {
      setErrorDate(
        `Date can't be further than ${monthsToAdd} months from today or the same as the effective date`
      );
      setValidDates([date]);
      return false;
    }
    if (
      date >
      getFirstDayOfMonth(billingDate.getFullYear(), billingDate.getMonth() + monthsToAdd)
    ) {
      setErrorDate(
        `Date can't be further than ${monthsToAdd} months from today or the same as the effective date`
      );
      setValidDates([date]);
      return false;
    }
    setValidDates(newValidDates);
    setErrorDate("");
    return true;
  };

  const handleIncorrectBillingDate = () => {
    // Hide Modal
    setInvalidBillingDate(false);

    // Update billing date
    member.billingDate = formatDateString(formatDate(new Date()));

    const nextMonth = getFirstDayOfMonth(billingDate.getFullYear(), billingDate.getMonth() + 1)
    const monthAfterNext = getFirstDayOfMonth(billingDate.getFullYear(), billingDate.getMonth() + 2)

    // Check if effective date is greater or equal to the first of the following month
    if (member.effectiveDate) {
      if (new Date(member.effectiveDate) <= nextMonth) {
        member.effectiveDate = formatDate(monthAfterNext);
      }
    }

  };

  // useEffect(() => {
  //   setDependentsArray(dependents);
  //   let calculatedValidDates: Date[] = [];

  //   const currentMonth = new Date().getMonth() + 1;
  //   let billingDateMonth = [billingDate.getMonth() + 1];

  //   if (billingDate.getMonth() + 1 === currentMonth) {
  //     billingDateMonth.push(billingDate.getMonth() + 2);
  //   }

  //   billingDateMonth.forEach((month) => {
  //     calculatedValidDates.push(
  //       getFirstDayOfMonth(billingDate.getFullYear(), month)
  //     );
  //   });
  //   setValidDates(calculatedValidDates);

  //   const formattedValidDates = calculatedValidDates.map((date) =>
  //     formatDate(date)
  //   );

  //   if (
  //     member.effectiveDate &&
  //     !formattedValidDates.includes(member.effectiveDate) &&
  //     !formattedValidDates.includes(selectedEffectiveDate)
  //   ) {
  //     setSelectedEffectiveDate(formattedValidDates[0]);
  //     member.effectiveDate = formattedValidDates[0];
  //   }
  // }, [billingDate]);


  // Initial useEffect to set valid dates
  useEffect(() => {
    setDependentsArray(dependents);
    let calculatedValidDates: Date[] = [];
    const monthsToAdd = SVGUplines.includes(agent.parentAgentId!) ? 6 : 2;

    for (let i = 1; i <= monthsToAdd; i++) {
      const month = billingDate.getMonth() + i;
      const validDate = getFirstDayOfMonth(billingDate.getFullYear(), month);
      if (validDate > billingDate) {
        calculatedValidDates.push(validDate);
      }
    }

    const today = new Date();

    const lastValidMonth = today.getMonth() + monthsToAdd;
    calculatedValidDates = calculatedValidDates.filter(
      (date) => date.getMonth() <= lastValidMonth
    );
    setValidDates(calculatedValidDates);
    handleChangeDate(billingDate);

    const formattedValidDates = calculatedValidDates.map((date) =>
      formatDate(date)
    );

    if (
      member.effectiveDate &&
      !formattedValidDates.includes(member.effectiveDate) &&
      !formattedValidDates.includes(selectedEffectiveDate)
    ) {
      setSelectedEffectiveDate(formattedValidDates[0]);
      member.effectiveDate = formattedValidDates[0];
    }
  }, [agent.parentAgentId]);

  useEffect(() => {
    setDependentsArray(dependents);
    let value = 0;
    const plans = [...selectedPlans, associatedPlan];
    plans.forEach((plan, index) => {
      if (plan?.rate && plan?.id) {
        if (index === plans.length - 1 && applicationDate + 1 >= aprilFirst) {
          if (plan?.rateUpdate) value += plan.rateUpdate;
        } else {
          value += plan.rate;
        }
      }
    });
    setTotalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans, applicationDate]);
  {/*Final Overview Price Issue: If new products are added, this useEffect needs to be edited to include the added plans to prevent the price from being displayed incorrectly on the confirmation page*/}
  useEffect(() => {
    if (
      selectedPlans[0].label !== PlanLabel.MetLifeElite1500 &&
      selectedPlans[0].label !== PlanLabel.MetLifeElite3000 &&
      selectedPlans[0].label !== PlanLabel.MetLifeElite5000 &&
      selectedPlans[0].label !== PlanLabel.NCDSmile1000byMetLife &&
      selectedPlans[0].label !== PlanLabel.NCDSmile2500byMetLife &&
      wantsDental
    ) {
      const tempArray = [...selectedPlans];
      if (applicationDate + 1 >= aprilFirst) {
        tempArray[0].rate =
          metlifeDentalData[selectedPlans[0].index || 0].PriceUpdated;
      } else {
        tempArray[0].rate =
          metlifeDentalData[selectedPlans[0].index || 0].Price;
      }
      setSelectedPlans(tempArray);
    }
  }, [applicationDate]);

  const planLogoHandler = () => {
    switch (selectedPlans[0]?.label) {
      case PlanLabel.NCDValueByMetLife:
        return "/ncd-value.svg";
      case PlanLabel.NCDEssentialsByMetLife:
        return "/ncd-essentials.svg";
      case PlanLabel.NCDCompleteByMetLife:
        return "/ncd-complete.svg";
      case PlanLabel.MetLifeElite1500:
        return "/ncd-elite-1500.svg";
      case PlanLabel.MetLifeElite3000:
        return "/ncd-elite-3000.svg";
      case PlanLabel.MetLifeElite5000:
        return "/ncd-elite-5000.svg";
      default:
        return "/ncd-value.svg";
    }
  };

  return (
    <div
      className={cx(
        "flex min-h-[70vh] w-full flex-col",
        "px-2 py-2",
        "xl:pt-17"
      )}
    >
      <div className="page-header-container">
        <h1 className="page-header-text">Overview</h1>
      </div>
      <div
        className={cx(
          "flex w-full flex-col",
          "rounded-3xl border-4",
          "border-black bg-white",
          "p-4",
          "xl:px-16 xl:pt-12"
        )}
      >
        <div
          className={cx(
            "grid grid-cols-1 gap-4",
            "text-xl",
            "md:grid-cols-4 md:gap-y-4 md:gap-x-20"
          )}
        >
          <div className="flex flex-col xl:max-w-[260px]">
            <div className="font-bold text-celadonBlue">Member</div>
            <div>{member.firstName}</div>
            <div> {member.lastName}</div>
          </div>
          <div className="flex flex-col xl:max-w-[260px]">
            <div className="font-bold text-celadonBlue">Date of Birth</div>
            <div>{member.dateOfBirth}</div>
          </div>
          <div className="flex flex-col xl:max-w-[260px]">
            <div className="font-bold text-celadonBlue">Gender</div>
            <div>{member.gender === "M" ? "Male" : "Female"}</div>
          </div>
          <div className="flex flex-col xl:max-w-[260px]">
            <div className="font-bold text-celadonBlue">Address</div>

            <div className="flex-col">{member.address1 + ","}</div>
            <div className="flex-col">{member.city + ","}</div>
            <div className="flex-col">
              {member.zipCode} {member.state}
            </div>
          </div>
          {dependentsArray.length > 0 && (
            <div className="my-2 block h-[2px] w-full bg-navyBlue md:hidden" />
          )}
          {dependentsArray?.map((dependent, i) => {
            return (
              <>
                <div className="flex flex-col xl:max-w-[260px]">
                  <div className="font-bold text-celadonBlue">
                    {dependent.relationship}
                  </div>
                  <div>{dependent.firstName}</div>
                  <div> {dependent.lastName}</div>
                </div>
                <div className="flex flex-col xl:max-w-[260px]">
                  <div className="font-bold text-celadonBlue">
                    Date of Birth
                  </div>
                  <div>{dependent.dateOfBirth}</div>
                </div>
                <div className="flex flex-col xl:max-w-[260px]">
                  <div className="font-bold text-celadonBlue">Gender</div>
                  <div>{dependent.gender === "M" ? "Male" : "Female"}</div>
                </div>

                <div className="hidden flex-col md:flex">
                  <div className="hidden font-bold text-celadonBlue">
                    Address
                  </div>
                </div>

                {i !== dependentsArray.length - 1 && (
                  <div className="my-2 block h-[2px] w-full bg-navyBlue md:hidden" />
                )}
              </>
            );
          })}
        </div>
        <div className="bottom-[5.5rem] z-[50] flex w-full flex-col bg-white pb-2">
          <div className="my-4 h-[2px] w-full bg-navyBlue" />

          <div
            className={cx(
              "flex-col items-center justify-center",
              "gap-[40px]",
              "xl:flex xl:flex-row xl:items-start"
            )}
          >
            <div
              className={cx(
                "flex items-center justify-between",
                "mb-4 gap-4",
                "xl:mb-0 xl:flex-col xl:items-start xl:gap-0"
              )}
            >
              <div className="text-2xl font-bold" data-cy="confirmationTotal">Total Monthly Cost</div>
              <div className="text-2xl font-bold text-navyBlue">
                ${totalValue}/mo
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="flex flex-col self-start">
                <label className="mb-[11px] flex flex-row items-center text-xl font-bold text-navyBlue">
                  Product Effective Date{" "}
                  <Tooltip
                    content="Effective date must be the first of the month following enrollment or the first of the following month."
                    className="z-50 bg-black bg-opacity-75 p-2"
                  >
                    <img
                      className="ml-1 h-5 w-5"
                      src="/question_mark_icon.svg"
                      alt=""
                    />
                  </Tooltip>
                </label>
                <select
                  className={`form-input border-celadonBlue bg-white xl:w-[262px] ${missingFields.includes("effectiveDate") && "border-red"
                    }`}
                  onChange={(event) => {
                    member.effectiveDate = event.target.value;
                    setSelectedEffectiveDate(event.target.value);
                    setApplicationDate(new Date(event.target.value).getMonth());
                  }}
                  value={selectedEffectiveDate}
                  defaultValue={member.effectiveDate}
                  required
                  data-cy="effectiveDate"
                >
                  <option value={""} hidden>
                    Select Date
                  </option>
                  {validDates?.map((date) => {
                    if (formatDate(date) === member.effectiveDate) {
                      return (
                        <option
                          className={date <= billingDate ? `hidden` : ``}
                          disabled={date <= billingDate}
                          value={formatDate(date)}
                          selected={date <= billingDate ? false : true}
                        >
                          {date.toDateString()}
                        </option>
                      );
                    }
                    return (
                      <option
                        className={date <= billingDate ? `hidden` : ``}
                        disabled={date <= billingDate}
                        value={formatDate(date)}
                      >
                        {date.toDateString()}
                      </option>
                    );
                  })}
                </select>
                {missingFields.includes("effectiveDate") && (
                  <h2 className="error">This field is required.</h2>
                )}
              </div>
              <div className="flex flex-col self-start">
                <label className="mb-[11px] flex flex-row items-center text-xl font-bold text-navyBlue xl:mt-0">
                  First Billing Date
                  <Tooltip
                    content="First billing date must be before the effective date."
                    className="z-50 bg-black bg-opacity-75 p-2"
                  >
                    <img
                      className="ml-1 h-5 w-5"
                      src="/question_mark_icon.svg"
                      alt=""
                    />
                  </Tooltip>
                </label>
                <DatePicker
                  className={`form-input w-full border-celadonBlue xl:w-[262px] ${missingFields.includes("billingDate") && "border-red"
                    }`}
                  onChange={(event) => {
                    if (event) {
                      if (handleChangeDate(event)) {
                        member.billingDate = formatDate(event);
                        setBillingDate(event);
                      }
                    }
                  }}
                  value={
                    member.billingDate && dateIsValid(member.billingDate)
                      ? formatDate(new Date(member.billingDate))
                      : formatDate(new Date())
                  }
                  placeholderText="MM/DD/YYYY"
                  popperContainer={({ children }) =>
                    createPortal(children, document.body)
                  }
                  dateFormat="MM-dd-yyyy"
                  minDate={new Date(getMinDateBilling())}
                  maxDate={new Date(getMaxDateBilling(agent))}
                  selected={billingDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {/* 
              <input
                type="date"
          
                className={`form-input border-celadonBlue xl:w-[197px] ${
                  missingFields.includes("billingDate") && "border-red"
                }`}
                onChange={(event) => {
                  if (
                    handleChangeDate(
                      new Date(formatDateString(event?.target?.value))
                    )
                  ) {
                    member.billingDate = formatDate(
                      new Date(formatDateString(event?.target?.value))
                    );
                    setBillingDate(
                      new Date(formatDateString(event?.target?.value))
                    );
                  }
                }}
                defaultValue={
                  member.billingDate && dateIsValid(member.billingDate)
                    ? new Date(member.billingDate).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                min={getMinDateBilling()}
                max={getMaxDateBilling()}
                required
              />
              */}
                {errorDate && <h2 className="error w-72">{errorDate}</h2>}
                {missingFields.includes("billingDate") && (
                  <h2 className="error">This field is required.</h2>
                )}
              </div>
            </div>
            <div className="mt-4 h-[52px] w-full font-inter text-2xl font-normal xl:mt-auto xl:ml-16 xl:w-[176px]">
              <Button
                text={"Confirm"}
                className="confirmPlan"
                action={() => {
                  if (errorDate === "") {
                    setStep((current) => current + 1);
                  }
                }}
                mainPath
              />
            </div>
          </div>
        </div>
        <div className={cx("grid grid-cols-1 md:grid-cols-3", "gap-6 pt-4")}>
          {[...selectedPlans, associatedPlan]?.map((plan, index) => {
            if (!plan?.id) return null;
            return (
              <div className="flex gap-3">
                <div className="w-[100px]">
                  <img
                    className={cx({
                      "mx-auto h-[45px] w-[45px]": index === 2,
                      "mx-auto h-[45px] w-[90px]": index !== 2,
                      "mx-auto": index === 0,
                    })}
                    src={
                      index === 0
                        ? planLogoHandler()
                        : index === 1
                          ? "/vsp.png"
                          : "/orange-plus.png"
                    }
                    alt=""
                  />
                </div>

                <div>
                  <div className="max-w-[150px] text-base font-normal">
                    {plan.label}
                  </div>
                  <div className="text-base font-bold text-navyBlue" data-cy={`finalPrice ${plan.label}`}>
                    $
                    {applicationDate + 1 >= aprilFirst && index === 2
                      ? plan.rateUpdate
                      : plan.rate}
                    /mo
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {invalidBillingDate && <BillingDateModal handleIncorrectBillingDate={handleIncorrectBillingDate} />}
      {/* <div className="grid w-full grid-cols-1 py-8 lg:grid-cols-2">
        <div className="grid grid-rows-1 border-4 border-lightBlue bg-white bg-opacity-80">
          <div className="row-span-1 flex w-full flex-col p-4 lg:p-8">
            <div className="flex flex-row gap-6 pt-4 text-navyBlue">
              {selectedPlans?.map((plan, index) => {
                if (!plan?.id) return null;
                return (
                  <>
                    <div>
                      <div className="flex flex-row">
                        <img
                          className="h-12 w-12"
                          src={index === 0 ? "/dental.png" : "/vision.png"}
                          alt=""
                        />
                        <div className="text-xl font-bold lg:text-3xl">
                          {plan.label}
                        </div>
                      </div>
                      <div className="my-4 text-2xl lg:text-4xl">
                        ${plan.rate}/mo
                      </div>
                    </div>
                    {index === 0 && (
                      <div className="h-44 w-px border-r border-orange" />
                    )}
                  </>
                );
              })}
            </div>
            <div className="max-h-[120px] overflow-y-auto lg:max-h-[300px]">
              <div className="mb-8 text-2xl font-semibold text-navyBlue lg:text-4xl">
                COVERING
              </div>
              <div>
                <div
                  className="cursor-pointer transition-all duration-300"
                  onClick={() => {
                    setMemberAccordionOpen(!memberAccordionOpen);
                  }}
                >
                  {memberAccordionOpen ? "+ " : "- "}
                  <span className="text-lg text-lightBlue underline lg:text-2xl">
                    {member.firstName} {member.lastName}
                  </span>
                  <div
                    className={`mt-3 pl-3 text-base text-navyBlue lg:text-lg ${
                      memberAccordionOpen ? "hidden" : "block"
                    }`}
                  >
                    <div>{member.dateOfBirth}</div>
                    <div>{member.gender}</div>
                    <div>
                      {member.address1} {member.address2}
                    </div>
                  </div>
                </div>
              </div>
              {dependentsArray?.map((dependent) => {
                return (
                  <div>
                    <div
                      className="cursor-pointer transition-all duration-300"
                      onClick={() => {
                        if (!dependentsAccordionsOpen.includes(dependent)) {
                          setDependentsAccordionsOpen((current) =>
                            current.concat([dependent])
                          );
                        } else {
                          setDependentsAccordionsOpen((current) =>
                            current.filter((element) => element !== dependent)
                          );
                        }
                      }}
                    >
                      {!dependentsAccordionsOpen.includes(dependent)
                        ? "+ "
                        : "- "}
                      <span className="text-lg text-lightBlue underline lg:text-2xl">
                        {dependent.firstName} {dependent.lastName}
                      </span>
                      <div
                        className={`mt-3 pl-3 text-base text-navyBlue lg:text-lg ${
                          !dependentsAccordionsOpen.includes(dependent)
                            ? "hidden"
                            : "block"
                        }`}
                      >
                        <div>{dependent.dateOfBirth}</div>
                        <div>{dependent.gender}</div>
                        <div>{dependent.relationship}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-4 flex flex-row justify-between">
              <div className="h-[40px] w-[130px] text-lg font-bold">
                <Button
                  text="CONTINUE"
                  action={() => setStep((current) => current + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Confirmation;
